import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";
import { HelmetTitle } from "../components/HelmetTitle";

const PrivacyPolicy = () => {
  return (
    <>
      <HelmetTitle title="Allo — Privacy Policy" />
      <PageWrapper footerDark>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="hero" className="text-center">
                    Privacy Policy
                  </Title>
                  <Text variant="small">
                    <p>Effective date: 08/15/2022</p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Introduction
                  </Title>
                  <Text variant="small">
                    <p>
                      Welcome to <b>Jomo Labs LLC</b>.
                    </p>
                    <p>
                      Jomo Labs LLC (&ldquo;us&rdquo;, &ldquo;we&rdquo;, or
                      &ldquo;our&rdquo;) operates Allo mobile application (here
                      in after referred to as &ldquo;
                      <b>Service</b>&rdquo;). Our Privacy Policy governs your
                      visit to Allo mobile application, and explains how we
                      collect, safeguard and disclose information that results
                      from your use of our Service. We use your data to provide
                      and improve Service. By using Service, you agree to the
                      collection and use of information in accordance with this
                      policy. Unless otherwise defined in this Privacy Policy,
                      the terms used in this Privacy Policy have the same
                      meanings as in our Terms and Conditions. Our Terms and
                      Conditions (&ldquo;<b>Terms</b>&rdquo;) govern all use of
                      our Service and together with the Privacy Policy
                      constitutes your agreement with us (&ldquo;
                      <b>agreement</b>
                      &rdquo;).
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Definitions
                  </Title>
                  <Text variant="small">
                    <p>
                      <b>SERVICE</b> means the Allo mobile application operated
                      by Jomo Labs LLC.
                    </p>
                    <p>
                      <b>PERSONAL DATA</b> means data about a living individual
                      who can be identified from those data (or from those and
                      other information either in our possession or likely to
                      come into our possession).
                    </p>
                    <p>
                      <b>USAGE DATA </b>is data collected automatically either
                      generated by the use of Service or from Service
                      infrastructure itself (for example, the duration of a page
                      visit).
                    </p>
                    <p>
                      <b>COOKIES</b> are small files stored on your device
                      (computer or mobile device).
                    </p>
                    <p>
                      <b>DATA CONTROLLER</b> means a natural or legal person who
                      (either alone or jointly or in common with other persons)
                      determines the purposes for which and the manner in which
                      any personal data are, or are to be, processed. For the
                      purpose of this Privacy Policy, we are a Data Controller
                      of your data.
                    </p>
                    <p>
                      <b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b> means any
                      natural or legal person who processes the data on behalf
                      of the Data Controller. We may use the services of various
                      Service Providers in order to process your data more
                      effectively.
                    </p>
                    <p>
                      <b>DATA SUBJECT</b> is any living individual who is the
                      subject of Personal Data.
                    </p>
                    <p>
                      <b>THE USER</b> is the individual using our Service. The
                      User corresponds to the Data Subject, who is the subject
                      of Personal Data.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Information Collection and Use
                  </Title>
                  <Text variant="small">
                    <p>
                      We collect several different types of information for
                      various purposes to provide and improve our Service to
                      you.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Types of Data Collected
                  </Title>
                  <Text variant="small">
                    <p>
                      <b>Personal Data</b>
                    </p>
                    <p>
                      While using our Service, we may ask you to provide us with
                      certain personally identifiable information that can be
                      used to contact or identify you (&ldquo;
                      <b>Personal Data</b>
                      &rdquo;). Personally identifiable information may include,
                      but is not limited to:
                    </p>
                    <List>
                      <li>Name.</li>
                      <li>Email address.</li>
                      <li>
                        Your third-party financial account information, such as
                        ID and password.
                      </li>
                    </List>
                    <p>
                      We may use your Personal Data to contact you with
                      information that may be of interest to you. You may opt
                      out of receiving any, or all, of these communications from
                      us by following the unsubscribe link.
                    </p>
                    <p>
                      <b>Usage Data</b>
                    </p>
                    <p>
                      We may also collect information that your browser sends
                      whenever you visit our Service or when you access Service
                      by or through a mobile device (&ldquo;<b>Usage Data</b>
                      &rdquo;).
                    </p>
                    <p>
                      This Usage Data may include information such as your
                      computer's Internet Protocol address (e.g. IP address),
                      browser type, browser version, the pages of our Service
                      that you visit, the time and date of your visit, the time
                      spent on those pages, unique device identifiers and other
                      diagnostic data.
                    </p>
                    <p>
                      When you access Service with a mobile device, this Usage
                      Data may include information such as the type of mobile
                      device you use, your mobile device unique ID, the IP
                      address of your mobile device, your mobile operating
                      system, the type of mobile Internet browser you use,
                      unique device identifiers and other diagnostic data.
                    </p>
                    <p>
                      <b>Tracking Cookies Data</b>
                    </p>
                    <p>
                      We use cookies and similar tracking technologies to track
                      the activity on our Service and we hold certain
                      information.
                    </p>
                    <p>
                      Cookies are files with a small amount of data which may
                      include an anonymous unique identifier. Cookies are sent
                      to your browser from a website and stored on your device.
                      Other tracking technologies are also used such as beacons,
                      tags and scripts to collect and track information and to
                      improve and analyze our Service.
                    </p>
                    <p>
                      You can instruct your browser to refuse all cookies or to
                      indicate when a cookie is being sent. However, if you do
                      not accept cookies, you may not be able to use some
                      portions of our Service.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Use of Data
                  </Title>
                  <Text variant="small">
                    <p>
                      Jomo Labs LLC uses the collected data for various
                      purposes:
                    </p>
                    <List>
                      <li>to provide and maintain our Service;</li>
                      <li>to notify you about changes to our Service;</li>
                      <li>
                        to allow you to participate in interactive features of
                        our Service when you choose to do so;
                      </li>
                      <li>to provide customer support;</li>
                      <li>
                        to gather analysis or valuable information so that we
                        can improve our Service;
                      </li>
                      <li>to monitor the usage of our Service;</li>
                      <li>to detect, prevent and address technical issues;</li>
                      <li>
                        to fulfill any other purpose for which you provide it;
                      </li>
                      <li>
                        to carry out our obligations and enforce our rights
                        arising from any contracts entered into between you and
                        us, including for billing and collection;
                      </li>
                      <li>
                        to provide you with notices about your account and/or
                        subscription, including expiration and renewal notices,
                        email-instructions, etc.;
                      </li>
                      <li>
                        to provide you with news, special offers and general
                        information about other goods, services and events which
                        we offer that are similar to those that you have already
                        purchased or enquired about unless you have opted not to
                        receive such information;
                      </li>
                      <li>
                        in any other way we may describe when you provide the
                        information;
                      </li>
                      <li>for any other purpose with your consent.</li>
                    </List>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Retention of Data
                  </Title>
                  <Text variant="small">
                    <p>
                      We will retain your Personal Data only for as long as is
                      necessary for the purposes set out in this Privacy Policy.
                      We will retain and use your Personal Data to the extent
                      necessary to comply with our legal obligations (for
                      example, if we are required to retain your data to comply
                      with applicable laws), resolve disputes, and enforce our
                      legal agreements and policies.
                    </p>
                    <p>
                      We will also retain Usage Data for internal analysis
                      purposes. Usage Data is generally retained for a shorter
                      period, except when this data is used to strengthen the
                      security or to improve the functionality of our Service,
                      or we are legally obligated to retain this data for longer
                      time periods.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Transfer of Data
                  </Title>
                  <Text variant="small">
                    <p>
                      Your information, including Personal Data, may be
                      transferred to &ndash; and maintained on &ndash; computers
                      located outside of your state, province, country or other
                      governmental jurisdiction where the data protection laws
                      may differ from those of your jurisdiction.
                    </p>
                    <p>
                      If you are located outside United States and choose to
                      provide information to us, please note that we transfer
                      the data, including Personal Data, to United States and
                      process it there.
                    </p>
                    <p>
                      Your consent to this Privacy Policy followed by your
                      submission of such information represents your agreement
                      to that transfer.
                    </p>
                    <p>
                      Jomo Labs LLC will take all the steps reasonably necessary
                      to ensure that your data is treated securely and in
                      accordance with this Privacy Policy and no transfer of
                      your Personal Data will take place to an organisation or a
                      country unless there are adequate controls in place
                      including the security of your data and other personal
                      information.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Disclosure of Data
                  </Title>
                  <Text variant="small">
                    <p>
                      We may disclose personal information that we collect, or
                      you provide:
                    </p>
                    <List>
                      <li>
                        Disclosure for Law Enforcement. Under certain
                        circumstances, we may be required to disclose your
                        Personal Data if required to do so by law or in response
                        to valid requests by public authorities.
                      </li>
                      <li>
                        Business Transaction. If we or our subsidiaries are
                        involved in a merger, acquisition or asset sale, your
                        Personal Data may be transferred.
                      </li>
                      <li>
                        Other cases. We may disclose your information also:
                        <List>
                          <li>
                            to contractors, service providers, and other third
                            parties we use to support our business;
                          </li>
                          <li>
                            to fulfill the purpose for which you provide it;
                          </li>
                          <li>
                            for any other purpose disclosed by us when you
                            provide the information;
                          </li>
                          <li>with your consent in any other cases;</li>
                          <li>
                            if we believe disclosure is necessary or appropriate
                            to protect the rights, property, or safety of the
                            Company, our customers, or others.
                          </li>
                        </List>
                      </li>
                    </List>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Security of Data
                  </Title>
                  <Text variant="small">
                    <p>
                      The security of your data is important to us but remember
                      that no method of transmission over the Internet or method
                      of electronic storage is 100% secure. While we strive to
                      use commercially acceptable means to protect your Personal
                      Data, we cannot guarantee its absolute security.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Your Data Protection Rights under the California Privacy
                    Protection Act (CalOPPA)
                  </Title>
                  <Text variant="small">
                    <p>
                      CalOPPA is the first state law in the nation to require
                      commercial websites and online services to post a privacy
                      policy. The law&rsquo;s reach stretches well beyond
                      California to require a person or company in the United
                      States (and conceivable the world) that operates websites
                      collecting personally identifiable information from
                      California consumers to post a conspicuous privacy policy
                      on its website stating exactly the information being
                      collected and those individuals with whom it is being
                      shared, and to comply with this policy. &ndash; See more
                      at:
                      https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                    </p>
                    <p>According to CalOPPA we agree to the following:</p>
                    <List>
                      <li>users can visit our site anonymously;</li>
                      <li>
                        our Privacy Policy link includes the word
                        &ldquo;Privacy&rdquo;, and can easily be found on the
                        page specified above on the home page of our website;
                      </li>
                      <li>
                        users will be notified of any privacy policy changes on
                        our Privacy Policy Page;
                      </li>
                      <li>
                        users are able to change their personal information by
                        emailing us at support@allo.finance.
                      </li>
                    </List>
                    <p>Our Policy on &ldquo;Do Not Track&rdquo; Signals:</p>
                    <p>
                      We honor Do Not Track signals and do not track, plant
                      cookies, or use advertising when a Do Not Track browser
                      mechanism is in place. Do Not Track is a preference you
                      can set in your web browser to inform websites that you do
                      not want to be tracked.
                    </p>
                    <p>
                      You can enable or disable Do Not Track by visiting the
                      Preferences or Settings page of your web browser.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Your Data Protection Rights under the California Consumer
                    Privacy Act (CCPA)
                  </Title>
                  <Text variant="small">
                    <p>
                      If you are a California resident, you are entitled to
                      learn what data we collect about you and ask to delete
                      your data. To exercise your data protection rights, you
                      can make certain requests and ask us what personal
                      information we have about you.
                    </p>
                    <List>
                      <li>
                        If you make this request, we will return to you:
                        <List>
                          <li>
                            The categories of personal information we have
                            collected about you.
                          </li>
                          <li>
                            The categories of sources from which we collect your
                            personal information.
                          </li>
                          <li>
                            The categories of third parties with whom we share
                            personal information.
                          </li>
                          <li>
                            The specific pieces of personal information we have
                            collected about you.
                          </li>
                        </List>
                      </li>
                      <p>
                        Please note, you are entitled to ask us to provide you
                        with this information up to two times in a rolling
                        twelve-month period. When you make this request, the
                        information provided may be limited to the personal
                        information we collected about you in the previous 12
                        months.
                      </p>

                      <li>
                        <b>To delete your personal information.</b> If you make
                        this request, we will delete the personal information we
                        hold about you as of the date of your request from our
                        records and direct any service providers to do the same.
                        In some cases, deletion may be accomplished through
                        de-identification of the information. If you choose to
                        delete your personal information, you may not be able to
                        use certain functions that require your personal
                        information to operate.
                      </li>

                      <p>
                        Please note if you ask us to delete your data, it may
                        impact your experience with us, and you may not be able
                        to participate in certain programs or membership
                        services which require the usage of your personal
                        information to function. But in no circumstances, we
                        will discriminate against you for exercising your
                        rights.
                      </p>
                      <p>
                        To exercise your California data protection rights
                        described above, please send your request(s) by one of
                        the following means:
                      </p>
                      <p>By email: support@allo.finance</p>
                    </List>
                    <p>
                      Your data protection rights, described above, are covered
                      by the CCPA, short for the California Consumer Privacy
                      Act. To find out more, visit the official California
                      Legislative Information website. The CCPA took effect on
                      01/01/2020.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Service Providers
                  </Title>
                  <Text variant="small">
                    <p>
                      We employ third party companies and individuals to
                      facilitate our Service (&ldquo;Service Providers&rdquo;),
                      provide Service on our behalf, perform Service-related
                      services or assist us in analysing how our Service is
                      used.
                    </p>
                    <p>
                      These third parties have access to your Personal Data only
                      to perform these tasks on our behalf and are obligated not
                      to disclose or use it for any other purpose.
                    </p>
                    <p>We do not sell information about you to third parties</p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Financial
                  </Title>
                  <Text variant="small">
                    <p>
                      We may use the third-party Financial Service Providers to
                      facilitate our Service.
                    </p>
                    <p>
                      <b>Plaid</b>
                    </p>
                    <p>
                      Plaid Inc. (&ldquo;Plaid&rdquo;) provides a service to
                      gather financial information from your relevant financial
                      institution. You agree to your personal and financial
                      information being transferred, stored, and processed by
                      Plaid.
                    </p>
                    <p>
                      For more information on what data Plaid collects for what
                      purpose and how the protection of the data is ensured,
                      please visit Plaid Privacy Policy page:
                      <a href="https://www.google.com/url?q=https://plaid.com/legal/%23end-user-privacy-policy&amp;sa=D&amp;ust=1591918241883000">
                        https://plaid.com/legal/#end-user-privacy-policy
                      </a>
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Analytics
                  </Title>
                  <Text variant="small">
                    <p>
                      We may use third-party Service Providers to monitor and
                      analyze the use of our Service.
                    </p>
                    <p>
                      <b>Amplitude</b>
                    </p>
                    <p>
                      Amplitude, Inc. (&ldquo;Amplitude&rdquo;) is an analytics
                      service that tracks and reports traffic.
                    </p>
                    <p>
                      For more information on what data Amplitude collects for
                      what purpose and how the protection of the data is
                      ensured, please visit Amplitude Privacy Policy page:
                      <a href="https://www.google.com/url?q=https://amplitude.com/privacy&amp;sa=D&amp;ust=1591918241885000">
                        https://amplitude.com/privacy
                      </a>
                      .
                    </p>
                    <p>
                      <b> Google Analytics</b>
                    </p>
                    <p>
                      Google Analytics is a web analytics service offered by
                      Google that tracks and reports website traffic. Google
                      uses the data collected to track and monitor the use of
                      our Service. This data is shared with other Google
                      services. Google may use the collected data to
                      contextualise and personalise the ads of its own
                      advertising network.
                    </p>
                    <p>
                      For more information on the privacy practices of Google,
                      please visit the Google Privacy Terms web page:
                      https://policies.google.com/privacy?hl=en We also
                      encourage you to review the Google's policy for
                      safeguarding your data:
                      https://support.google.com/analytics/answer/6004245.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    CI/CD tools
                  </Title>
                  <Text variant="small">
                    <p>
                      We may use third-party Service Providers to automate the
                      development process of our Service.
                    </p>
                    <p>
                      <b>GitHub</b>
                    </p>
                    <p>
                      GitHub, Inc. (&ldquo;GitHub&rdquo;) is a development
                      platform to host and review code, manage projects, and
                      build software.
                    </p>
                    <p>
                      For more information on what data GitHub collects for what
                      purpose and how the protection of the data is ensured,
                      please visit GitHub Privacy Policy page:
                      <a href="https://www.google.com/url?q=https://help.github.com/en/articles/github-privacy-statement&amp;sa=D&amp;ust=1591918241886000">
                        https://help.github.com/en/articles/github-privacy-statement.
                      </a>
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Links to Other Sites
                  </Title>
                  <Text variant="small">
                    <p>
                      Our Service may contain links to other sites that are not
                      operated by us. If you click a third party link, you will
                      be directed to that third party's site. We strongly advise
                      you to review the Privacy Policy of every site you visit.
                    </p>
                    <p>
                      We have no control over and assume no responsibility for
                      the content, privacy policies or practices of any third
                      party sites or services.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Children's Privacy
                  </Title>
                  <Text variant="small">
                    <p>
                      Our Services are not intended for use by children under
                      the age of 18 (&ldquo;<b>Child</b>&rdquo; or &ldquo;
                      <b>Children</b>&rdquo;).
                    </p>
                    <p>
                      We do not knowingly collect personally identifiable
                      information from Children under 18. If you become aware
                      that a Child has provided us with Personal Data, please
                      contact us. If we become aware that we have collected
                      Personal Data from Children without verification of
                      parental consent, we take steps to remove that information
                      from our servers.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Changes to This Privacy Policy
                  </Title>
                  <Text variant="small">
                    <p>
                      We may update our Privacy Policy from time to time. We
                      will notify you of any changes by posting the new Privacy
                      Policy on this page.
                    </p>
                    <p>
                      We will let you know via email and/or a prominent notice
                      on our Service, prior to the change becoming effective and
                      update &ldquo;effective date&rdquo; at the top of this
                      Privacy Policy.
                    </p>
                    <p>
                      You are advised to review this Privacy Policy periodically
                      for any changes. Changes to this Privacy Policy are
                      effective when they are posted on this page.
                    </p>
                  </Text>
                </Box>
                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                    Contact Us
                  </Title>
                  <Text variant="small">
                    <p>
                      If you have any questions about this Privacy Policy,
                      please contact us:
                    </p>
                    <p>By email: support@allo.finance.</p>
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default PrivacyPolicy;
